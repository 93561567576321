import React from "react";
import Link from "next/link";

function HowItWorks() {
    return (
        <section
            id="HowItWorks"
            className="section section-lg py-5 px-3 px-md-4 px-lg-6"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <h2 className="text-center"> How it works</h2>
                </div>
                <div className="row justify-content-center py-5">
                    <div className="py-2 px-1 col-12 px-2 col-md-6 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="pt-2 pr-2">
                                <span className="badge badge-dark">1</span>
                            </div>
                            <i className="fas fa-home fa-3x text-dark"></i>
                        </div>
                        <h5 className="text-center my-2">
                            Select the house design
                        </h5>
                        <p className="text-center mb-1">
                            Select the house design that best meets your budget
                            and needs
                        </p>

                        <Link href="/plans">
                            <a className="">
                                <p className="analytics-view-plans-link text-center">
                                    Check out the designs
                                </p>
                            </a>
                        </Link>
                    </div>
                    <div className="py-2 px-1 col-12 px-2 col-md-6 col-lg-3">
                        <div className="text-center">
                            <div className="row justify-content-center">
                                <div className="pt-2 pr-2">
                                    <span className="badge badge-dark">2</span>
                                </div>
                                <i className="fas fa-mobile-alt fa-3x text-dark"></i>
                            </div>
                        </div>
                        <h5 className="text-center my-2">Download the app</h5>
                        <p className="text-center mb-1">
                            Download the Inuua app from the Play Store and
                            access your pre-uploaded project based on your
                            selected architectural plan.
                        </p>
                        {/* <a
                        className=""
                        href="https://play.google.com/store/apps/details?id=com.inuuaprojectmanagement"
                        target="_blank"
                        rel="noopener"
                    >
                        <p className="analytics-inuua-main-app-on-playstore-link text-center">
                            Download the app here
                        </p>
                    </a> */}
                    </div>
                    <div className="py-2 px-1 col-12 px-2 col-md-6 col-lg-3">
                        <div className="text-center">
                            <div className="row justify-content-center">
                                <div className="pt-2 pr-2">
                                    <span className="badge badge-dark">3</span>
                                </div>
                                <i className="far fa-clock fa-3x text-dark"></i>
                            </div>
                        </div>
                        <h5 className="text-center my-2">
                            Manage your project at your pace
                        </h5>
                        <p className="text-center mb-1">
                            Maximise your time and money by planning the
                            materials and labourers you need on site at any one
                            time, right from your phone.
                        </p>
                        {/* <a
                        className=""
                        href="https://play.google.com/store/apps/details?id=com.inuuaprojectmanagement"
                        target="_blank"
                        rel="noopener"
                    >
                        <p className="analytics-inuua-main-app-on-playstore-link text-center">
                            Give it a test run
                        </p>
                    </a> */}
                    </div>
                    <div className="py-2 px-1 col-12 px-2 col-md-6 col-lg-3">
                        <div className="text-center">
                            <div className="row justify-content-center">
                                <div className="pt-2 pr-2">
                                    <span className="badge badge-dark">4</span>
                                </div>
                                <i className="fas fa-door-open fa-3x text-dark"></i>
                            </div>
                        </div>
                        <h5 className="text-center my-2">Move in</h5>
                        <p className="text-center">
                            Move into your dream home!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;
