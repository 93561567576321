import React from "react";
// import LazyImage from "../../molecules/layout/Images/LazyImage";
import Image from "@image";
import Link from "next/link";

const testimonials = [
    {
        customer_name: "Joe Ocholla",
        location: "Rongau",
        image: "images/testimonials/JoeOchollaCaseStudy.jpg",
        testimony:
            "I settled any doubts by buying plans and drawings for my home at their offices. During construction they give honest advice, onsite and on email with photos to help me make informed decisions. I think their project tools could prove useful too.",
        blog_post:
            "blog/posts/inuua-case-study-1-joe-ocholla-rangau-ongata-rongai-town/",
        plan: "",
    },
    {
        customer_name: "D. Nganga",
        location: "Juja",
        image: "images/testimonials/DNgangaCaseStudy.jpg",
        testimony:
            "The Inuua Team took the time to help us understand the plan we liked and made small adjustments as required within a few days of payment. We appreciate their honesty and dedication to service.",
        blog_post: "",
        plan: "4232 4 Bedroom Maisonette House Plan",
    },
];

function Testimonials() {
    return (
        <section id="testimonials" className="section">
            <div className=" container justify-content-center mb-5 mb-lg-5">
                <h2 className="text-center mb-5">Testimonials</h2>

                <div className="row">
                    {/* testimonial cards     */}
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="container mx-1 shadow bg-white">
                            <div className="d-flex justify-content-center py-3">
                                <i className="fas fa-quote-left fa-lg text-dark"></i>
                            </div>

                            <p className="py-3">
                                I settled any doubts by buying plans and
                                drawings for my home at their offices. During
                                construction they give honest advice, onsite and
                                on email with photos to help me make informed
                                decisions. I think their project tools could
                                prove useful too.{" "}
                                <Link href="blog/posts/inuua-case-study-1-joe-ocholla-rangau-ongata-rongai-town/">
                                    <a>Read the full case study.</a>
                                </Link>
                            </p>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <Image
                                        height="70px"
                                        width="70px"
                                        quality={1}
                                        alt="Inuua Tujenge testimonial - Joe Ocholla"
                                        src="/images/testimonials/JoeOchollaCaseStudy.jpg"
                                        className="rounded-circle "
                                    />
                                </div>

                                <div className="row col-12 my-2 justify-content-center">
                                    <p className="text-center">Joe Ocholla</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="container mx-1 shadow bg-white">
                            <div className="d-flex justify-content-center py-3">
                                <i className="fas fa-quote-left fa-lg text-dark"></i>
                            </div>

                            <p className="py-3">
                                We thank Inuua, for their wonderful house
                                design, which met our desire for our first home
                                project. <br />
                                Their prompt services were seamless. from
                                acquiring the blue prints and BQ's to the permit
                                application process. <br />
                                We are grateful and recommend them for any other
                                person interested in building their own home.
                            </p>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <Image
                                        height="70px"
                                        width="70px"
                                        quality={1}
                                        alt="Inuua Tujenge testimonial - D Nganga"
                                        src="/images/testimonials/DNgangaCaseStudy.jpg"
                                        className="rounded-circle "
                                    />
                                </div>

                                <div className="row col-12 my-2 justify-content-center">
                                    <p className="text-center">EG</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-6 col-lg-4">
                        <div className="container mx-1 shadow bg-white">
                            <div className="d-flex justify-content-center py-3">
                                <i className="fas fa-quote-left fa-lg text-dark"></i>
                            </div>

                            <p className="py-3">
                                The Inuua Team took the time to help us
                                understand the plan we liked and made small
                                adjustments as required within a few days of
                                payment. We appreciate their honesty and
                                dedication to service.
                            </p>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <LazyImage
                                        alt="Inuua Tujenge testimonial - D Nganga"
                                        src="/images/testimonials/DNgangaCaseStudy.jpg"
                                        className="rounded-circle "
                                        style={{
                                            height: "70px",
                                            width: "70px",
                                            // objectFit: "cover",
                                        }}
                                    />
                                </div>

                                <div className="row col-12 my-2 justify-content-center">
                                    <p className="text-center">D Nganga</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="container mx-1 shadow bg-white">
                            <div className="d-flex justify-content-center py-3">
                                <i className="fas fa-quote-left fa-lg text-dark"></i>
                            </div>

                            <p className="py-3">
                                Once I bought the plan for my Mum’s home, the
                                Inuua Team passed by her site in Ruai to help
                                with practical honest advice. They regularly ask
                                how they can help and I recommend you sending in
                                photos regularly. They really do want to help
                                you build your beautiful home!
                            </p>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <Image
                                        height="70px"
                                        width="70px"
                                        quality={1}
                                        alt="Inuua Tujenge testimonial Dr. E. Mugambi"
                                        src="/images/testimonials/DrMugambi.jpg"
                                        className="rounded-circle "
                                    />
                                </div>

                                <div className="row col-12 my-2 justify-content-center">
                                    <p className="text-center">
                                        Dr. E. Mugambi
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end testimonial cards */}
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
