import React from "react";
import Image from "@image";
// import LazyImage from "../../../molecules/layout/Images/LazyImage";

function InuuaIsMuchMoreThanAHousePlan() {
    return (
        <section
            id="InuuaIsMuchMoreThanAHousePlan"
            className="section section-lg bg-soft py-lg-5 py-3 py-3 "
        >
            <div className="container">
                <div className="row justify-content-center my-5">
                    <h2 className="text-center">
                        INUUA is much more than a house plan
                    </h2>
                </div>
                <div className="container my-5">
                    <div className="rounded-lg d-flex justify-content-center">
                        <Image
                            src="/images/home/house_one.jpg"
                            height={450}
                            width={900}
                            className="inuua-is-more-than-a-house-plan-image"
                        />
                    </div>
                </div>
                {/* Explanations  */}
                <div className="container">
                    <div className="row">
                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className=" container py-4 px-5 bg-dark text-white"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="fas fa-home fa-3x"></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Choose a beautiful, well thought out,
                                        modern architectural design by top
                                        architects, perfect for your family and
                                        budget, complete with floor plans and
                                        engineering drawings for your plumbing
                                        and electrical circuitry.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className="container py-4 px-5 bg-white text-dark"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="fas fa-file-invoice-dollar fa-3x "></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Included with your house plan is a Bill
                                        of Quantities (Cost Estimate) uploaded
                                        for you in the Free app which enables
                                        you to manage project costs, labour and
                                        required materials. If needed, you can
                                        use the Bill of Quantities to access
                                        financing.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className="container py-4 px-5 bg-white text-dark"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="fas fa-chart-line fa-3x text-dark"></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Track your materials and labour costs at
                                        your pace and monitor your progress with
                                        photos your supervisor shares from your
                                        site across every stage of your project,
                                        right from your phone.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className="container py-4 px-5 bg-white text-dark"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="fas fa-truck fa-3x text-dark"></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Access suppliers near your site. Compare
                                        quotes right from your phone and have
                                        your goods delivered. All payment is
                                        secure and held in Escrow until goods
                                        are delivered. You will enjoy special
                                        discounts and deals exclusively through
                                        the app.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className="container py-4 px-5 bg-white text-dark"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="fas fa-mobile-alt fa-3x text-dark"></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Use our Builder's Guide in the app for
                                        expert advice and resources for every
                                        stage of construction.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 col-md-6 col-lg-4">
                            <div
                                style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                }}
                                className="container py-4 px-5 bg-white text-dark"
                            >
                                <div className="row mb-3 justify-content-start">
                                    <i className="far fa-clock fa-3x text-dark"></i>
                                </div>
                                <div className="row my-1">
                                    <p>
                                        Join a community of home builders like
                                        you! Share your on-ground experiences
                                        and be encouraged to finish your build!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InuuaIsMuchMoreThanAHousePlan;
