import React from "react";
// import LazyImage from "../../../molecules/layout/Images/LazyImage";
import Image from "@image";

export default function SaveUpTo40Percent() {
    return (
        <section id="SaveUpTo40Percent" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 d-flex align-items-end pb-5 man-phone ">
                        <Image
                            src="/images/home/manPhone.png"
                            alt="Inuua save up to 40% man holding phone"
                            height={430}
                            width={290}
                            // className="h-100"
                        />
                    </div>
                    {/* ...      */}
                    <div className="col-12 col-lg-8">
                        <div className="container">
                            <div className="row mb-3 ml-md-4">
                                <h2>Save up to 40% on your building costs</h2>
                                <p>
                                    By managing your project using INUUA you can
                                    save up to 40%, here's how;
                                </p>
                            </div>
                            {/* bullet points */}
                            <div className="row ">
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 1</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                You control every stage of the
                                                construction removing the need
                                                for a contractor. This way, you
                                                save on what would've been your
                                                contractors 'profit'.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 2</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                Through the app, you will know
                                                the right amount of material to
                                                purchase for every stage of the
                                                construction.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 3</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                The app also gives you access to
                                                competitive quotes from highly
                                                rated suppliers near you.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 4</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                At each stage of the
                                                construction you will have full
                                                knowledge of the materials
                                                needed to complete the build. No
                                                more Fundi guesstimates!!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 5</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                With inuua's stock control
                                                system, you have full control,
                                                accountability and oversight of
                                                your materials' issuance from
                                                storage. This builds
                                                transparency.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 my-md-2">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-1 font-weight-bold">
                                            <p className="h2 text-dark"> 6</p>
                                        </div>
                                        <div className="col-10">
                                            <p>
                                                An in built builder's diary
                                                helps you keep track of all
                                                aspects of the construction
                                                journaling the process for
                                                future reference.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end bullet points */}
                            {/* <div className="row">
                                <div className="col-12 col-md-6 col-lg-3 mt-3">
                                    <div className="container d-flex flex-column align-items-center justify-content-center">
                                        <a
                                            className="analytics-inuua-main-app-on-playstore-link default-button "
                                            href="https://play.google.com/store/apps/details?id=com.inuuaprojectmanagement"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <LazyImage
                                                className="analytics-inuua-main-app-on-playstore-link default-button"
                                                src="/images/GetItOnGooglePlayBadge.svg"
                                                alt="Inuua app on Google PlayStore"
                                            />
                                        </a>

                                        <p
                                            className="mt-2 text-center text-dark font-weight-bold"
                                            style={{ fontSize: "small" }}
                                        >
                                            Download Inuua app
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
