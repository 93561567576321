import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";

function LazyImage({
    src,
    webpSrc,
    alt,
    className,
    style,
    height,
    width,
    forceWebP,
}) {
    return (
        <picture>
            {!!webpSrc &&
            (forceWebP ||
                (!webpSrc.startsWith("http://") &&
                    !webpSrc.startsWith("https://"))) ? (
                <source srcSet={webpSrc} type="image/webp" />
            ) : null}

            {/* {(!!webpSrc &&
                //  skip at dev
                forceWebP) ||
                (!webpSrc.startsWith("http://") &&
                    !webpSrc.startsWith("https://") ? (
                        //
                        <source srcSet={webpSrc} type="image/webp" />
                    ))} */}
            <source
                srcSet={src}
                type={src.substr(-4) === ".png" ? "image/png" : "image/jpeg"}
            />
            <LazyLoadImage
                src={src}
                className={className}
                alt={alt}
                style={style}
                height={height}
                width={width}
            />
        </picture>
    );
}

LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    webpSrc: PropTypes.string,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LazyImage;
